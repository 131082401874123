@font-face {
  font-family: "brilliant";
  src: url('../fonts/brilliant.eot');
  src: url('../fonts/brilliant.eot?#iefix') format('eot'),
    url('../fonts/brilliant.woff') format('woff'),
    url('../fonts/brilliant.ttf') format('truetype'),
    url('../fonts/brilliant.svg#brilliant') format('svg');
}

.br:before {
  font-family: "brilliant";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.br-car:before {
  content: "\E001";
}

.br-clock:before {
  content: "\E002";
}

.br-close:before {
  content: "\E003";
}

.br-money:before {
  content: "\E004";
}

.br-plus:before {
  content: "\E005";
}

.br-quote:before {
  content: "\E006";
}
