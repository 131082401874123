.form-control {
  box-shadow: none;

  &:focus {
    border-color: $primary-color;
    box-shadow: 0px 0px 5px lighten($primary-color,10%);
  }
}

.comment-form .form-control,
.form-contact .form-control {
  margin-bottom: 20px;
}

select,
textarea,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[name="value"], {
  @extend .form-control;
}

input[type="number"] {
  padding: 10px;
  font-size: 12px;
}

select {
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
}

textarea,
select,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

[type="reset"] {
  text-decoration: underline;
}

[type="reset"], [type="reset"]:hover {
  background-color: transparent !important;
  border: none;

  color: $secondary-color !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;

  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

select,
select.form-control {
  padding-top: 0;
  padding-bottom: 0;
}

// Reset height for `textarea`s
textarea {
  min-height: 200px;
}

legend {
  font-size: 25px;
  font-weight: 400;
  font-family: $font-family-secondary;
}

label sup {
  color: red;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border-color: $border-color;
}

.select2-container .select2-selection--single {
  height: 40px;

  &:focus {
    outline: none;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  color: $secondary-color;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.br-lookbook-wrapper .select2-container .select2-selection--single .select2-selection__rendered,
.br-lookbook-wrapper .select2-results__option {
  font-family: $font-family-third;
}

.select2-container--default .select2-selection--single .select2-selection__arrow  {
  right: 10px;
  height: 100%;
  cursor: pointer;

  b {
    border: none;
    margin-top: -13px;

    &:after {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjcxOSIgaGVpZ2h0PSI4LjI4IiB2aWV3Qm94PSIwIDAgOC43MTkgOC4yOCI+ICA8cGF0aCBpZD0iYS5zdmciIGNsYXNzPSJjbHMtMSIgZD0iTTU0OS44MDksMjY1NS4wN2EwLjc0MSwwLjc0MSwwLDAsMC0uMjA2LTAuNTFsLTAuNDE5LS40MmEwLjczOCwwLjczOCwwLDAsMC0uNTA4LTAuMjEsMC43MTcsMC43MTcsMCwwLDAtLjUuMjFsLTEuNjQyLDEuNjR2LTMuOTNhMC43MTYsMC43MTYsMCwwLDAtLjcxNS0wLjcxSDU0NS4xYTAuNzE2LDAuNzE2LDAsMCwwLS43MTUuNzF2My45M2wtMS42NDItMS42NGEwLjcxNywwLjcxNywwLDAsMC0uNS0wLjIxLDAuNzM4LDAuNzM4LDAsMCwwLS41MDguMjFsLTAuNDEzLjQyYTAuNzIxLDAuNzIxLDAsMCwwLS4yMTMuNTEsMC43LDAuNywwLDAsMCwuMjEzLjVsMy42MzUsMy42NGEwLjcxNywwLjcxNywwLDAsMCwuNS4yMSwwLjczOCwwLjczOCwwLDAsMCwuNTA4LTAuMjFsMy42MzYtMy42NEEwLjcxNywwLjcxNywwLDAsMCw1NDkuODA5LDI2NTUuMDdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTQxLjA5NCAtMjY1MS4xMykiLz48L3N2Zz4=");
    }
  }
}
.select2-dropdown {
  border-color: $border-color;
  border-radius: 0;
}

.select2-results__option {
  padding: 8px 20px;
  color: $secondary-color;
  margin: 0;
  width: 100%;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $primary-color;
  color: white;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: lighten($primary-color,5%);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0;
  background: transparent;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: $primary-color;
}

// CSS custom ui-spinner
.ui-widget-content {
  border: none;
}

.ui-spinner-input {
  margin: 0;
}

.ui-state-default .ui-icon {
  background-image: none;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr,
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-radius: 0;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: none;
  background: transparent;
}

.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;

  min-width: 100px;
}

.ui-spinner-input {
  padding: 10px 20px!important;
}

.ui-spinner-button {
  width: 20px;

  height: 50%;
  font-size: .5em;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  cursor: default;
  display: block;
  overflow: hidden;
  right: 0;
}

.ui-spinner .ui-icon {
  text-indent: 0;
  font-size: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  position: absolute;
  margin-top: -8px;
  top: 50%;
  left: 0;

  &:before {
    font-family: FontAwesome;
    font-size: 9px;
    color: $secondary-color;

    position: absolute;
    right: 10px;
    left: 0;
  }
}

.ui-spinner-up {
  top: 0;
}
.ui-spinner-down {
  bottom: 0;
}

.ui-spinner .ui-icon-triangle-1-n:before {
  content: "\f062";
  bottom: 0;
}

.ui-spinner .ui-icon-triangle-1-s:before {
  content: "\f063";
  top: -3px;
}

// CSS .form-search
.form-search {
  position: relative;

  .form-control {
    height: 50px;
  }

  button, .btn {
    @include absolute(50%,1px);
    transform:translateY(-50%);
    border: none;
    min-width: auto;
    font-size: 14px;
    color: $secondary-color;
    background-color: white;

     &:hover, &:focus {
      color: $primary-color;
      background-color: transparent;
     }
  }
}

// CSS br-register, .br-login
.br-register, .br-login {
  .form-row {
    margin-bottom: 20px;
  }

  .btn {
    font-family: $font-family-third;
  }
}

.br-login {
  .lost_password {
    float: right;
    margin-top: 10px;
    font-family: $font-family-third;
    color: $text-color;
    font-size: 12px;

    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

// CSS .br-form-checkout
.br-form-checkout {
  label:not(.c-input) {
    @extend .h6;
    text-transform: uppercase;
  }

  input,
  textarea {
    @include placeholder($text-color);
  }

  textarea {
    min-height: 160px;
  }
}

.br-checkbox {
  @include media('>=xs') {
    p {
      padding-left: 30px;
    }
  }
}

