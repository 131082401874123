.site-footer {
  .widget-title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .br-footer-content [class*="col-"] {
    margin-bottom: 30px;

    @include media('>=lg') {
      margin-bottom: 0;
    }
  }

  .widget ul li a, .widget ol li a {
    font-size: 12px;
    color: $text-color;

    &:hover, &:focus {
      color: $primary-color;
    }
  }

  .widget ul li,
  .widget ol li {
    color: $text-color;
  }

  .widget_rss ul li,
  .widget_rss ol li,
  .widget_nav_menu ul li,
  .widget_nav_menu ol li,
  .widget_archive ul li,
  .widget_archive ol li,
  .widget_categories ul li,
  .widget_categories ol li,
  .widget_links ul li,
  .widget_links ol li,
  .widget_meta ul li,
  .widget_meta ol li,
  .widget_pages ul li,
  .widget_pages ol li,
  .widget_recent_comments ul li,
  .widget_recent_comments ol li,
  .widget_recent_entries ul li,
  .widget_recent_entries ol li,
  .widget_recent_post ul li,
  .widget_recent_post ol li {
    margin-bottom: 3px;
    padding-bottom: 3px;
    border: none;
  }

  .br-logo-wrapper {
    padding-right: 20px;
  }

  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin: 0 0 10px;
    font-size: 12px;
  }

  .br-dropdown {

    @include media('<sm') {
      margin: 20px 0 0;

      .dropdown:first-child {
        margin-left: 0;
      }
    }
  }
}

.br-dropdown {
  @include media('>=sm') {
    display: inline-block;
    float: right;
  }
}

.br-footer-content {
  padding: 40px 0;
}

.site-footer--black {
  background-color: $secondary-color;

  .br-site-social, .br-site-info, .dropdown-menu {
    border-color: #3a3a3a;
  }

  .widget-title, .br-site-social a, .br-payment a {
    color: white;
  }

  .br-site-social a, .br-payment a {
    &:hover, &:focus {
      color: $primary-color;
    }
  }

  .form-control, .form-control:focus {
    border: none;
    box-shadow: none;
  }

  .btn {
    @extend .btn-primary;
  }
}

// CSS .site-footer--style2
.site-footer--style2 {

  .widget {
    border-left: 1px solid #3a3a3a;
    padding: 15px 20px;
    margin: 15px 0;

    @include media('>=md') {
      min-height: 200px;
      width: 33.33333%;
      float: left;
      padding-bottom: 0;
      margin: 0;
    }
  }

  .br-site-contact {
    margin-top: 20px;
  }



  @include media('>=xs','<sm') {
    .widget-text form .btn {
      width: auto;
      min-width: 100px;
    }
  }

  @include media('>=md') {
    .br-widget-wrapper {
      padding: 30px 0;
      border-bottom: 1px solid #3a3a3a;
      overflow: hidden;
    }

    .br-site-contact {
      display: inline-block;
      float: right;
      margin-top: 0;
      margin-right: 15px;
    }

    .br-widget-wrapper .widget:last-child {
      border-right: 1px solid #3a3a3a;
    }
  }

  .nav-social {
    a {
      font-size: 16px !important;
      color: white !important;

      &:hover, &:focus {
        color: $primary-color !important;
      }
    }
  }

  .nav-social li:not(:last-child),
  .nav-social ul li:not(:last-child) {
    margin-right: 10px;
  }

  .nav-social li {
    margin-bottom: 0 !important;
  }
}
