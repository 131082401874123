// CSS navtab
.nav-tabs {
  > li {
    float: none;
    display: inline-block;

    > a {
      @extend .h2;
      text-transform: uppercase;
      padding-left: 25px;
      padding-right: 25px;

      @include media('<sm') {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0;
      }

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}

.list-description {
  padding-left: 20px;

  li {
    color: $text-color;
    margin: 5px 0;
  }
}
