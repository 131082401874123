.dropdown-menu {
  box-shadow: none;
  padding: 0;
  margin: 0;
  min-width: 100px;

  > li > a {
    padding: 4px 20px;
  }
}

.br-dropdown .dropdown {
  display: inline-block;

  &:not(:first-child) {
    margin-left: 15px;
  }
}

.open > .dropdown-menu {
  z-index: 9999999;
}

.dropdown {
  > a {
    position: relative;

    .fa-caret-down {
      margin-left: 4px;
    }
  }

  .count {
    height: 25px;
    width: 25px;
    line-height: 25px;
    background-color: $primary-color;
    border-radius: 50%;
    overflow: hidden;

    color: white;
    text-align: center;
    font-size: 12px;
    font-family: $font-family-third;

    position: absolute;
    top: 0;
    right: -7px;
  }

  .count-wishlist {
    background-color: #e5b299;
  }
}

// CSS .dropdown-animation
.dropdown-animation {
  .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.25s;
    transform: translateY(15px);
  }

  &.open {
    .dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.navbar-icons .dropdown-menu {
  left: auto;
  min-width: 280px;
  margin-top: 1px;

  @include media('<xs') {
    border: none;
  }
}

.dropdown-right {
  float: right;
}

// CSS .dropdown-search
.dropdown-search {

  @include media('>=xs') {
    right: -163px;
    width: 400px;
  }

  @include media('>=md','<lg') {
    right: -133px;
  }

  .form-search input[type="text"] {
    border: none;
  }
}

// CSSS .dropdown-cart
.dropdown-cart {
  right: -108px !important;

  @include media('>=xs') {
    width: 360px;
  }

  @include media('>=md','<lg') {
    right: -89px !important;
  }
}

// CSS .dropdown-wishlist
.dropdown-wishlist {
  right: -53px !important;

  @include media('>=xs') {
    width: 360px;
  }

  @include media('>=md','<lg') {
    right: -43px !important;
  }
}

// CSS dropdown-account
.dropdown-account {
  right: -1px;
  padding: 25px 20px;
  min-width: 195px !important;

  @include media('>=md','<lg') {
    right: -1px;
  }
}

.account-link {
  color: $primary-color;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

