.page-title {
  position: relative;
  padding: 110px 0 105px;
  background-color: $secondary-color;
}

.page-title--white {
  color: white;

  h1, .h1, .breadcrumb > .active, .breadcrumb li a, .breadcrumb > li + li:before {
    color: white;
  }

  .breadcrumb li:hover a {
    color: lighten($primary-color,10%);
  }

  &:before {
    content: "";
    @include overlay(black,0.3)
  }
}

.page-title__main {
  position: relative;
  z-index: 5;
  text-align: center;
}

// CSS .page-title--classic
.page-title--classic {
  min-height: 300px;
  max-height: 400px;
  padding: 0;

  &.page-title--white {
    .container {
      background-color: $secondary-color;
    }

    .page-title__main {
      border: none;
    }
  }

  > .container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    margin: 0 25px;

    @include media('>=sm') {
      width: 780px;
    }

    @include media('>=lg') {
      width: 1200px;
    }
  }

  .page-title__main {
    padding: 25px 0 21px;
    border-bottom: $border-solid;

    @include media('>=sm') {
      margin: 0 15px;
    }
  }

  @include media('>=sm') {
    .container {
      margin: auto;
    }

    .page-title__main {
      text-align: left;
    }

    .breadcrumb {
      text-align: right;
    }
  }
}

// CSS .page-title-basic
.page-title-basic {
  .page-title__main {
    text-align: left;
    padding: 25px 0;
    overflow: hidden;

    @include media('>=md') {
      .breadcrumb {
        float: right;
      }

      h1 {
        float: left;
      }
    }
  }
}
