.br-testimonial {
  text-align: center;
  margin-bottom: 30px;
}

.br-testimonial__avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 20px;

  > img {
    width: 100%;
    height: 100%;
  }
}

// CSS .br-testimonial__main
.br-testimonial__say {
  color: $secondary-color;
  margin-bottom: 15px;

  p {
    &:after, &:before {
      font-size: 20px;
    }

    &:after {
      content: "”";
    }

    &:before {
      content: "“";
    }
  }
}

.br-testimonial__name {
  margin-bottom: 5px;
}

// CSS .br-testimonial--classic
.br-testimonial--classic {
  p {
    &:after, &:before {
      display: none;
    }
  }

  .br-testimonial__say:before {
    @include br("\E006");
    color: #ebc0ab;
    font-size: 20px;
    margin-bottom: 30px;
    display: inline-block;
  }

  .br-testimonial__say {
    padding: 40px 50px 55px;
    background-color: $bg-color;
    margin-bottom: 0;
  }

  .br-testimonial__avatar {
    width: 66px;
    height: 66px;
    line-height: 66px;

    margin-top: -33px;
    margin-bottom: 10px;
  }
}

.js-testimonial {
  margin-left: 5px;
  margin-right: 5px;
}

.br-testimonial--white {
  .br-testimonial__say, .br-testimonial__name {
    color: white !important;
  }
}
