// CSS .br-product__media
.br-product__media {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  background-color: #f8f8f8;
}

.br-product-detail .br-product__media {
  overflow: visible;
}

.br-product__thumb {
  @extend .br-img;
  cursor: pointer;
}

.br-product__action {
  .btn {
    height: 45px !important;
    min-width: 45px;
    line-height: 45px !important;
    text-transform: uppercase;

    margin-right: 10px;
    padding: 0 10px;
    display: inline-block;
    font-size: 14px;

    span {
      margin-left: 10px;
    }
  }

  .table-cell-center:last-child a {
    margin-right: 0;
  }

  .tooltip-inner, .tooltip.top .tooltip-arrow {
    margin-bottom: 7px;
  }
}

.br-product--grid {
  margin-bottom: 35px;

  .br-product__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.br-product--grid .br-product__action {
  display: block;
  height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 30px;
  visibility: hidden;
  color: $tooltip-color;
  text-align: center;
  background-color: $secondary-color;
  transform: translate(0, 100%);
  transition: all 0.35s ease;

  a {
    color: $tooltip-color;

    transition: transform 0.35s;
    transform: translate(0, 200%);

    &:hover {
      color: $primary-color;
    }
  }
}

.br-product__label-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.br-product__label {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  font-family: $font-family-third;
  letter-spacing: 1px;

  display: block;
  min-width: 70px;
  height: 25px;
  line-height: 25px;
  padding: 0 5px;
  margin-bottom: 10px;
  background-color: #8bd485;

  &.hot {
    background-color: #e65f5f;
  }

  &.sale {
    background-color: #f1ab5f;
  }

  &.popular {
    background-color: #529be0;
  }
}

// CSS .br-product__main
.br-product__name {
  color: $text-color;
  font-weight: 400;
  font-family: $font-family-base;
  margin-bottom: 7px;

  a {
    color: $text-color;
  }

  &:hover a {
    color: $primary-color;
  }
}

.br-product__price {
  font-family: $font-family-third;
  color: $secondary-color;
  display: inline-block;

  del {
    font-size: 12px;
    color: lighten($text-color,20%);
    margin-left: 10px;
  }
}

.br-product--grid .br-product__price {
  margin-bottom: 0;
}

.br-product--grid .br-product__main {
  position: relative;

  .br-select-color {
    @include absolute(null,0,0,null);
  }
}

.br-product__description {
  margin-bottom: 25px;
}

.sku {
  margin: 0 20px
}

// CSS br-product
.br-product {
  overflow: hidden;

  &:not(.br-product--sold-out):hover {
    .br-product__action {
      visibility: visible;
      transform: translate(0, 0);
      a {
        transform: translate(0, 0);
      }

      .table-cell-center {
        &:first-child a {
          transition-delay: 0.1s;
        }

        &:nth-child(2) a {
          transition-delay: 0.15s;
        }

        &:nth-child(3) a {
          transition-delay: 0.2s;
        }

        &:nth-child(4) a {
          transition-delay: 0.25s;
        }
      }
    }
  }

  &.br-product--sold-out {
    .br-product__media:before {
      content: "";
      @include overlay();
    }
  }
}

.sold-out {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  font-size: 12px;
  font-family: $font-family-third;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:after, &:before {
    font-size: 12px;
    font-family: $font-family-third;
    color: white;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    content: "\\  /";
    margin-top: -21px;
  }

  &:after {
    content: "/  \\";
    margin-top: 25px;
  }
}

.br-product__image {
  position: relative;

  .br-icon {
    position: absolute;
    z-index: 99;

    &:focus [data-toggle="tooltip"] {
      visibility: hidden;
      opacity: 0;
    }

    &.br-play {
      left: 20px;
      bottom: 20px;
    }

    &.br-enlarge {
      bottom: 4px;
      right: 0;
    }
  }
}

// CSS .product-mini
.product-mini__img {
  width: 90px;
  padding-right: 20px;
  display: inline-block;
  position: relative;

  @include media('>=xs') {
    padding-right: 30px;
    width: 108px;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 24px;
    line-height: 24px;
    min-width: 24px;
    text-align: center;
    color: white;
    background-color: $secondary-color;
    font-family: $font-family-third;
  }
}

.product-mini__body {
  display: inline-block;
}

.dropdown-menu {
  .product-mini__body {
    width: 145px;

    @include media('>=xs') {
      width: 167px;
    }
  }

  .list-product-mini {
    padding: 30px 20px;
  }
}

.product-mini {
  position: relative;
  margin-bottom: 20px;
}

.product-mini__button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  > a {
    display: block;
    margin-bottom: 5px;
    color: $text-color;
    font-size: 14px;
    line-height: 20px;
    width: 20px;
    text-align: center;
    opacity: 1;

    &:hover, &:focus {
      color: $primary-color;
    }

    &.close:hover, &.close:focus {
      color: red;
    }
  }
}

.product-mini__action {
  .btn {
    display: block;
    width: 100%;
    text-align: center;

    & + .btn {
      margin-top: 10px;
    }
  }

  @include media('>=xs') {
    .btn {
      width: 150px;

      & + .btn {
        margin-top: 0;
      }
    }

    .btn:nth-child(2n+1) {
      float: right;
    }
  }
}

.total-product {
  padding: 20px 0;
  margin-bottom: 20px;
  border-top: $border-solid;
  border-bottom: $border-solid;
}
