//== Breakpoints
$breakpoints: (tn: 320px, xs: 480px, sm: 768px, md: 992px, lg: 1200px);


//== Colors
$primary-color:         #7fc9c4;
$secondary-color:       #242626;

$brand-primary:         #7fc9c4;
$brand-success:         #92c97f;
$brand-info:            #6dadda;
$brand-warning:         #e2b870;
$brand-danger:          #d27575;


// Utils
$border-color:          #e3e3e3;
$border-solid:          1px solid $border-color;


//== Scaffolding
$text-color:            #898989;
$link-color:            $secondary-color;
$link-hover-color:      $primary-color;
$link-hover-decoration: none;

$bg-color:              #f9f9f9;


//== Typography
$font-family-montserrat:  'Montserrat', sans-serif;
$font-family-lato:        'Lato', sans-serif;
$font-family-playfair:    'Playfair Display', serif;
$font-family-hind:        'Hind', sans-serif;

$font-family-base:        $font-family-lato;
$font-family-secondary:   $font-family-playfair;
$font-family-third:       $font-family-montserrat;
$font-family-four:        $font-family-hind;

$font-size-base:          14px;
$font-size-large:         12px;
$font-size-small:         10px;

$font-size-h1:            30px;
$font-size-h2:            20px;
$font-size-h3:            18px;
$font-size-h4:            16px;
$font-size-h5:            $font-size-base;
$font-size-h6:            12px;

$line-height-base:        1.71428571; // 24/14

$headings-font-family:    $font-family-montserrat;
$headings-font-weight:    700;
$headings-line-height:    1.3;
$headings-color:          $secondary-color;


//== Components
$padding-base-vertical:     10px;
$padding-base-horizontal:   20px;

$padding-xs-vertical:       5.5px;
$padding-xs-horizontal:     7px;

$padding-small-vertical:    8px;
$padding-small-horizontal:  15px;

$padding-large-vertical:    14px;
$padding-large-horizontal:  25px;

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;


//== Buttons
$btn-font-weight:                700;
$btn-font-size:                  10px;

$btn-default-color:              white;
$btn-default-bg:                 $secondary-color;
$btn-default-border:             transparent;

$btn-primary-border:             transparent;
$btn-success-border:             transparent;
$btn-info-border:                transparent;
$btn-warning-border:             transparent;
$btn-danger-border:              transparent;


//== Forms
$input-color:                    $secondary-color;
$input-border:                   $border-color;
$input-border-focus:             darken($border-color,5%);
$input-color-placeholder:        $secondary-color;

$input-height-base:              40px;
$form-group-margin-bottom:       20px;

$legend-color:                   $secondary-color;
$legend-border-color:            transparent;

//== Dropdowns
$dropdown-border:                $border-color;
$dropdown-link-color:            $text-color;

$dropdown-link-hover-color:      $primary-color;
$dropdown-link-hover-bg:         white;

$dropdown-link-active-color:     $primary-color;
$dropdown-link-active-bg:        white;

$dropdown-link-disabled-color:   white;
$dropdown-link-disabled-color:   $primary-color;
$dropdown-caret-color:           $text-color;


//== Tabs
$nav-tabs-border-color:                     transparent;
$nav-tabs-link-hover-border-color:          transparent;

$nav-tabs-active-link-hover-bg:             transparent;
$nav-tabs-active-link-hover-color:          $primary-color;
$nav-tabs-active-link-hover-border-color:   transparent;


//== Pagination
$pagination-color:                     $secondary-color;
$pagination-bg:                        transparent;
$pagination-border:                    transparent;

$pagination-hover-color:               $secondary-color;
$pagination-hover-bg:                  transparent;
$pagination-hover-border:              $border-color;

$pagination-active-color:              white;
$pagination-active-bg:                 $primary-color;
$pagination-active-border:             $primary-color;


//== Form states and alerts
$state-success-text:             white;
$state-success-bg:               $brand-success;
$state-success-border:           $brand-success;

$state-info-text:                white;
$state-info-bg:                  $brand-info;
$state-info-border:              $brand-info;

$state-warning-text:             white;
$state-warning-bg:               $brand-warning;
$state-warning-border:           $brand-warning;

$state-danger-text:              white;
$state-danger-bg:                $brand-danger;
$state-danger-border:            $brand-danger;

//Alert
$alert-padding: 10px 20px;


//== Tooltips
$tooltip-color:               $bg-color;
$tooltip-bg:                  $secondary-color;
$tooltip-opacity:             1;


//== Modals
$modal-content-bg:                             transparent;
$modal-content-border-color:                   transparent;
$modal-content-fallback-border-color:          transparent;
$modal-backdrop-bg:           white;
$modal-backdrop-opacity:      .9;


//== Panels
$panel-bg:                    #fff;
$panel-body-padding:          30px 15px;
$panel-heading-padding:       15px 20px;
$panel-inner-border:          $border-color;
$panel-footer-bg:             white;

$panel-default-text:          $secondary-color;
$panel-default-border:        transparent;
$panel-default-heading-bg:    white;


//== Breadcrumbs
$breadcrumb-padding-horizontal: 0;

$breadcrumb-bg:                 transparent;
$breadcrumb-color:              $text-color;
$breadcrumb-active-color:       $secondary-color;
$breadcrumb-separator:          "\f105";

//== Type

$blockquote-small-color:      $text-color;
$blockquote-font-size:        30px;
$blockquote-border-color:     transparent;

//== Close
 $close-text-shadow : none;
$close-color:         white;
