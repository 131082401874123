.br-card__title {
  font-weight: 400;
  margin-bottom: 5px;
}

.br-card__main {
  text-align: center;
  padding: 15px 15px 0;
}

.br-card__hover {
  position: absolute;
  background-color: rgba($secondary-color,0.6);
  width: 100%;
  height: 100%;

  > a {
    font-size: 13px;
    font-weight: 400;

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: white;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    z-index: 5;

    &:hover, &:focus {
      color: white;
      background-color: $primary-color;
    }
  }
}

.br-card__thumb {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: "";
    position: relative;
    display: block;
    padding-top: 72.222%;
    transform: translateZ(0);
    background-color: $bg-color;
    z-index: -1;
  }

  > img, a > img {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.br-card, .grid-sizer {
  position: relative;
  width: 100%;

  @include media('>=xs') {
    width: 50%;
    float: left;
  }

  @include media('>=sm') {
    width: 33.33333%;
  }
}

// CSS Style .br-card--quad
.br-card.br-card--quad .br-card__thumb:before {
  padding-top: 100%;
}

.br-card.br-card--quad-w2 {
  width: 66.66667%;
}

.inner-title {
  color: lighten($text-color,20%);

  .br-card__title ,.br-card__title a {
    color: white;

    &:hover, &:focus {
      color: lighten($primary-color,10%);
    }
  }

  .br-card__hover:after {
    display: none;
  }

  .br-card__main {
    @include absolute(null,0,null,0);
    top: 50% !important;
    z-index: 20;
    padding-bottom: 15px;

    transform: translateY(-50%) !important;
  }

  .br-card__main-noh {
    @include absolute(0,0,0,0);
    z-index: 20;
    padding-bottom: 15px;
    text-align: center;

    .slide-banner-content {
      @extend .tb;
    }

    &.text-left {
      padding: 0 30px;

      @include media('>=md') {
        padding: 0 80px;
      }
    }
  }
}

.br-card-wrapper .br-card .br-card__media .br-card__thumb .br-card__hover {
  top: 0px;
  left: -100%;
  transition: all 0.3s ease-in-out;
}

.br-card-wrapper .br-card .br-card__media .br-card__thumb:hover .br-card__hover{
  left: 0px;
}

@each $data-gap in 5, 10, 15, 30 {
  .br-card-wrapper {
    &[data-gap="#{$data-gap}"] {
      margin: calc(-#{$data-gap}px / 2);

      .br-card {
        padding: calc(#{$data-gap}px / 2);
      }

      .br-card.br-card--quad-h2 .br-card__thumb:before {
        padding-top: calc(200% + #{$data-gap}px);
      }

      .br-card.br-card--quad-w2 .br-card__thumb:before {
        padding-top: calc(50% - #{$data-gap}px / 2);
      }

      &.br-card-categories {
        .br-card.br-card--quad-h2 .br-card__thumb:before {
          padding-top: calc(120% + #{$data-gap}px - 1px) !important;
        }

        .br-card.br-card--quad-w2 .br-card__thumb:before {
          padding-top: calc(30% - #{$data-gap}px / 2) !important;
        }
      }
    }
  }
}

.br-portfolio {
  height: auto !important;
}

.br-card-wrapper {
  overflow: hidden;

  &[data-col="6"] {
    .br-card, .grid-sizer {
      width: 50%;
    }

    @include media('>=xs') {
      .br-card, .grid-sizer {
        width: 25%;
      }
    }

    @include media('>=md') {
      .br-card, .grid-sizer {
        width: 16.66667%;
      }

      .br-card.br-card--quad-w2 {
        width: 33.33333%;
      }
    }
  }

  &[data-col="5"] {
    .br-card.br-card--quad-w2 {
      width: 100%;
    }

    @include media('>=sm') {
      .br-card, .grid-sizer {
        width: 20%;
      }

      .br-card.br-card--quad-w2 {
        width: 40%;
      }
    }
  }

  &[data-col="4"] {
    .br-card.br-card--quad-w2 {
      width: 100%;
    }

    @include media('>=sm') {
      .br-card, .grid-sizer {
        width: 50%;
      }
    }

    @include media('>=md') {
      .br-card, .grid-sizer {
        width: 25%;
      }

      .br-card.br-card--quad-w2 {
        width: 50%;
      }
    }
  }

  &[data-col="3"] {
    @include media('>=xs','<sm') {
      .br-card, .grid-sizer {
        width: 100%;
      }
    }
  }

  &[data-col="2"] {
    .br-card, .grid-sizer {
      width: 50%;
    }

    .br-card.br-card--quad-w2 {
      width: 100%;
    }
  }
}

// CSS .br-card-categories
.br-card-categories {
  .br-card.br-card--quad .br-card__thumb:before {
    padding-top: 60%;
  }

  .br-card, .grid-sizer {

    @include media('<sm') {
      width: 100% !important;
      display: block;

      &:not(.br-card--quad-h2) {
        display: none;
      }
    }
  }
}

.br-card-fullwide .br-card__thumb:before {
  padding-top: 75%;
}

.br-card-categories__item {

  .br-card__thumb:after {
    content: "";
    @include overlay($secondary-color,0.1);
  }

  .br-card-categories__title {
    color: white;
  }

  @include media('>=md') {
    .br-card__main {
      transform: translateY(-15px) !important;
    }
  }
}

.br-card-categories__title {
  font-weight: 400;
  margin-bottom: 30px;

  span {
    font-family: $font-family-secondary;
    font-size: 50px;
    text-transform: capitalize;

    @include media('>=sm','<md') {
      font-size: 30px;
    }
  }
}

// CSS .br-card-categories--2
.br-card-categories--2 {
  .br-card__thumb:before {
    padding-top: 62.5%;
  }

  @include media('>=md') {
    .br-card__main {
      transform: translateY(-50%) !important;
    }
  }
}

.br-card-big {
  margin-bottom: 30px;

  .br-card__thumb:before {
    padding-top: 157.5%;
  }
}

// CSS .br-card--fullwidth
.br-card--fullwidth {
  .br-card {
    height: 620px;
  }

  .inner-title .br-card__main {
    transform: none !important;
    padding: 80px 0;
    top: 0 !important;
    height: 100%;
    position: relative;
  }

  .brand-horizontal {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid #bcbcbc;
  }

  .br-testimonial {
    padding: 0 30px;

    @include media('>=xs') {
      padding: 0 50px;
    }

    @include media('>=lg') {
      padding-left: 120px;
      padding-right: 120px;
    }
  }

}

.br-card-wrapper-nojs {
  @extend .br-card-wrapper;
}

// CSS .br-card-instagram
.br-card-instagram {
  position: relative;

  .br-card-instagram-content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    z-index: 20;
  }
}

.br-card-instagram-content {
  letter-spacing: 2px;

  h2 {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: -7px;

    @include media('>=sm') {
      font-size: 30px;
    }
  }

  span {
    font-size: 14px;

    @include media('>=sm') {
      font-size: 20px;
    }
  }

  @include media('<sm') {
    .text-center, .text-right {
      text-align: left !important;
    }
  }
}

// data-item
.br-card-banner {
  &[data-item] {
    .br-card__thumb:before {
      padding-top: 64.32% !important;
    }
  }

  &[data-item="1"] {

    .br-card, .br-card--quad-w2 {
      width: 100%;
    }
  }

  &[data-item="2"] {

    .br-card {
      width: 100%;
    }

    @include media('>=xs') {
      .br-card {
        width: 50%;
      }
    }
  }

  &[data-item="3"] {
    @include media('<sm') {
      .br-card, .br-card--quad-w2 {
        width: 100%;
      }
    }
  }
}

.br-card-banner-2 {
  @extend .br-card-banner;

  &[data-item] {
    .br-card__thumb:before {
      padding-top: 118% !important;
    }

    .br-card__thumb img {
      height: 100%;
    }
  }

  .br-card--quad-w2 .br-card__thumb:before {
    padding-top: 101% !important;
  }
}

.br-card-shopsimple {
  .br-card, .grid-sizer {
    width: 30%;
  }

  @include media('>=xs','<sm') {
    .br-card {
      width: 50% !important;
    }

    .br-card.br-card--quad-w2 {
      width: 100% !important;
    }
  }

  @include media('>=sm') {
    .br-card.br-card--quad-w2 {
      width: 70%;
    }
  }
}
