.br-lookbook__name {
  font-size: 30px;
  font-weight: 400;
  font-family: $font-family-secondary;

  @include media('>=xs') {
    font-size: 40px;
  }
}

.br-lookbook__description {
  color: $secondary-color;
  margin-bottom: 25px;

  @include media('>=xs') {
    margin-bottom: 55px;
  }
}

.br-lookbook__main {
  padding: 100px 20px 40px;
  text-align: center;
  background-color: $bg-color;

  margin-top: -70px;

  @include media('>=md') {
    padding-top: 150px;
    margin-top: -120px;
  }

  @include media('>=lg') {
    padding-top: 200px;
    margin-top: -170px;
  }
}

.br-lookbook__gallery img {
  width: 100%;
}

.br-lookbook {
  position: relative;
  margin-bottom: 50px;

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  @include media ('>=md') {
    margin-bottom: 75px;

    .slick-prev {
      left: -70px;
    }

    .slick-next {
      right: -70px;
    }
  }
}

.br-lookbook.br-lookbook--moderm {
  background-color: $bg-color;

  .br-title {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: -80px;

    @include media('>=md') {
      top: -120px;
    }

    @include media('>=lg') {
      top: 55px;
      padding-left: 8.33333%;
      text-align: left;
    }
  }

  .br-lookbook__main {
    text-align: left;
    padding: 30px;
    margin-top: 0;
  }

  @include media('<sm') {
    .slick-prev, .slick-next {
      display: none !important;
    }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  @include media ('>=lg') {
    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  @include media('>=sm') {
    padding: 60px 0;
    max-height: 500px;

    .br-lookbook__main {
      width: 50%;
      float: left;
      padding: 0 15px 0 0;
    }

    .br-lookbook__gallery {
      padding-left: 15px;
      width: 48%;

      @include absolute(0,0,0,null);
      overflow: hidden;
    }
  }

  @include media('>=md') {
    margin-bottom: 150px;

    .br-lookbook__gallery {
      padding-left: 15px;
      width: 48%;

      top: -100px; // -40-60
      bottom: -100px; // -40-60
    }
  }

  @include media('>=lg') {

    padding-top: 130px;
    padding-bottom: 100px;
    margin-bottom: 190px;

    .br-lookbook__gallery {
      padding-left: 15px;
      width: 48%;

      top: -190px; // -130-60
      bottom: -160px; // -100-60
    }

    .br-lookbook__main {
      margin-top: 0;
    }
  }

  &:nth-child(even) {
    @include media('>=sm') {
      .br-lookbook__gallery {
        right: auto;
        left: 0;
        padding-left: 0;
        padding-right: 15px;
      }

      .br-lookbook__main {
        float: right;
        padding-right: 0;
        padding-left: 15px;
      }
    }
  }
}

// CSS .section-lookbook-detail
.section-lookbook-detail {
  h2 {
    font-weight: 400;
  }

  .overflow-hidden .pull-left.btn i {
    margin-left: 0;
    margin-right: 5px;
  }
}

.br-lookbook-detail {
  .br-filter-toggle-icon {
    float: none;
  }

  .overflow-hidden > a {
    margin-bottom: 20px;
  }

  @include media('>=xs') {

    > .overflow-hidden > a:last-child {
      float: right;
    }
  }

  @include media('>=sm') {
    > .overflow-hidden > a {
      margin-bottom: 0;
    }

    .br-filter-toggle-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
