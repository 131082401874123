.list-style-none {
  list-style: none;
  padding-left: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.bold {
  font-weight: 700 !important;
}

.normal {
  font-weight: 400 !important;
}

.tb {
  display: table;
  width: 100%;
  height: 100%;
}

.tb-cell,
.table-cell-center {
  display: table-cell;
  vertical-align: middle;
}

.table-cell-center {
  width: 1%;
  text-align: center;
}

.text-left {
  text-align: left !important;
}

@each $pt in 0, 5, 10, 25, 30, 40, 55, 60, 70, 80  {
  .pt-#{$pt} {
    padding-top: #{$pt}px !important;
  }
}

@each $pb in 0, 10, 20, 25, 30, 40, 50, 55, 60, 70, 80, 110  {
  .pb-#{$pb} {
    padding-bottom: #{$pb}px !important;
  }
}

@each $mb in 0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 85, 140 {
  .mb-#{$mb} {
    margin-bottom: #{$mb}px !important;
  }
}

@each $background-color in white, green, blue, red, black {
  .bg-#{$background-color} {
    background-color: #{$background-color} !important;
  }
}

@each $fz in 12, 16, 18, 20, 25, 30, 40, 50, 60 {
  .fz-#{$fz} {
    font-size: #{$fz}px !important;
  }
}


@include media('<md') {
  @each $mb in 30 {
    .mb-md-#{$mb} {
      margin-bottom: #{$mb}px !important;
    }
  }

  .pt-md-20 {
    padding-top: 20px !important;
  }

  .pb-md-20 {
    padding-bottom: 20px !important;
  }
}

@include media('<sm') {
  .fz-sm-30 {
    font-size: 30px !important;
  }
}

.white {
  color: white;
}

.dark {
  color: $secondary-color !important;
}

.light {
  color: $text-color;
}

.mt--90 {
  margin-top: -90px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}


.ml-5 {
  margin-left: 10px;
}

.w-160 {
  width: 160px;
}

.br-bb {
  border-bottom: $border-solid;
}

.bd-bt {
  border-top: $border-solid;
  margin-top: 30px;
  padding-top: 30px;
}

.email {
  color: $primary-color;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

a {
  transition: all ease .25s;

  &:hover, &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

.section {
  padding-top: 80px;
  padding-bottom: 100px;
}

ul, ol {
  margin-bottom: 0;

  li {
    color: $secondary-color;
  }
}

.small {
  font-size: 12px;
  font-family: $font-family-third;
}

.uppercase {
  font-family: $font-family-third;
  text-transform: uppercase;
}

.font-secondary {
  font-family: $font-family-secondary;
}

.font-third {
  font-family: $font-family-third;
}

.font-four {
  font-family: $font-family-four;
}

.br-ul {
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 15px;
    line-height: 30px;

    &:before {
      @include fa("\f111");
      font-size: 4px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

ol.list-style-none {
  li .number {
    display: inline-block;
    margin-right: 10px;
    line-height: 30px;
  }
}

.br-relative {
  position: relative;

  .br-title {
    margin-bottom: 20px;
  }

  .br-filter-toggle-icon {
    padding-bottom: 15px;
    display: inline-block;
  }

  @include media('>=xs') {
    .br-title {
      display: inline-block;
      margin-bottom: 40px;
    }

    .br-filter-toggle-icon {
      float: right;
      padding-top: 10px;
    }
  }
}

// CSS .line
.line {
  margin: 0 auto 20px;
  width: 100px;
  height: 2px;
  background-color: black;
  display: block;
}

// CSS no-gutter
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Tiny grid
@include media('>=xs', '<sm') {
  @include make-grid(tn);
}
