@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &.btn-outline {
    color: darken($background, 10%);
    background-color: transparent;
    border-color: $background;
  }

  &.btn-white {
    color: white;
    background-color: transparent;
    border-color: white;
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: $border;
  }

  &:hover, &.hover {
    color: $color;
    background-color: lighten($background, 5%);
    border-color: $border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: $border;

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: $border;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
