.js-br-product-nav {
  padding: 0 50px;
  margin-top: 10px;

  .br-product__thumb:before {
    padding-top: 129.4%;
  }

  @include media('<xs') {
    display: none;
  }
}

.br-product-nav__item {
  padding: 5px;
  @at-root {
    .mfp-content {
      .br-product-nav__item {
        float: none;
      }
    }
  }

  .br-product__thumb {
    border: 2px solid transparent;
  }

  &.slick-current .br-product__thumb {
    border-color: $primary-color;
  }
}

.modal-enlarge {
  text-align: center;

  ul {
    padding: 60px;
    display: inline-block;
    @extend .list-style-none;

    @include media('>=xs') {
      padding-left: 100px;
      padding-right: 100px;
    }

    li {
      margin-bottom: 20px;
    }
  }

  .modal-dialog {
    margin: auto;
  }

  .close {
    position: fixed;
    top: 20px;
    right: 20px;
    opacity: 1;

    &:hover, &:focus {
      background-color: transparent;
    }

    @include media('>=sm') {
      right: 30px;
    }
  }
}

.variation-price .br-product__price,
.woocommerce-variation-price .br-product__price {
  font-size: 20px !important;

  del {
    font-size: 18px !important;
  }
}

.br-product-detail  {
  background-color: #f8f8f8;
  padding-top: 80px;
  padding-bottom: 60px;

  select, input[type="value"], .select2-container {
    margin-bottom: 10px;
    width: 100% !important;

    @include media('>=sm') {
      margin-bottom: 5px;
    }
  }

  [type="reset"] {
    padding: 5px 5px 5px 0;
    font-weight: 400;
  }

  @include media('<sm') {
    .ui-spinner {
      width: 100%;
    }
  }
}

.br-product-detail__container {
  padding: 20px 15px;
  background-color: white;

  @include media('>=xs') {
    padding: 30px;
  }

  @include media('<xs') {
    form > .row > [class*="col-"] {
      width: 100% !important;
    }
  }

  .overflow-hidden {
    font-size: 12px;
    color: $secondary-color;
  }

  .nav-social {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;

    li {
      margin-right: 7px;

      a {
        font-size: 14px;
      }
    }
  }
}

.br-product-detail__header {
  margin-bottom: 15px;
  border-bottom: $border-solid;
  position: relative;
  min-height: 60px;
  padding-bottom: 15px;

  .breadcrumb {
    margin-right: 85px;
  }
}

.br-product-detail__main {
  border-bottom: $border-solid;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

// CSS .br-product-slide-vertical
.br-product-slide-vertical {
  background-color: white;
  position: relative;

  .br-product__media {
    background-color: white;
  }

  .slick-dots {
    background-color: transparent;
  }

  .br-product-slide-vertical-image {
    background-color: #f8f8f8;
    margin-bottom: 0;
    padding-bottom: 15px;

    @include media('>=md') {
      margin-right: 10px;
    }
  }

  .br-product__description {
    margin-bottom: 17px;
  }

  @include media('>=xs') {

    .br-product__image {
      margin-right: 80px;
      padding-right: 7px;
      background-color: white;
    }

    .br-product-slide-vertical-image {
      background-color: white;
    }
  }

  .js-br-product-nav {
    padding: 0;
    margin: 0;

    .slick-list {
      margin-top: 35px; // 37px -2px(border-size)
      z-index: 99;
    }

    .slick-track {
      padding-bottom: 80px;
      overflow: hidden;
    }

    .slick-prev, .slick-next {
      left: 50%;
      margin-left: -20px;
      transform: rotate(90deg);
    }

    .slick-prev {
      top: -37px;
    }

    .slick-next {
      top: auto;
      bottom: -31px;
    }

    .br-product__thumb:before {
      padding-top: 134%;
    }
  }

  .br-product-nav-wrapper {
    display: none;

    @include media('>=xs') {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 70px;
      width: 81px;
      height: 100%;
      padding: 0;
    }

    .br-product-nav__item {
      padding: 2px 0;
    }
  }

  .br-product-detail__container {
    padding: 0;
  }
}

.in-stock {
  display: inline-block;
  text-align: center;
  letter-spacing: 1px;
  height: 25px;
  line-height: 25px;
  width: 70px;
  background-color: $secondary-color;
  color: white;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
  font-family: $font-family-third;
}

// CSS .section-detail
.section-detail {
  .br-product-slide-vertical {
    padding: 30px;

    @include media('>=lg') {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .br-site-social {
    padding: 15px 0;
  }
}

//
.modal-backdrop {
  z-index: 1000000;
}

.modal {
  z-index: 1000010;
}

.mfp-wrap {
  letter-spacing: 0 !important;
}


.mfp-bg {
  background-color: rgba($secondary-color,0.8);
  opacity: 0.8;
}
//
.mfp-content {
  .br-product-detail {
    padding: 20px;
  }

  .mfp-close {
    font-size: 24px;
    font-weight: 300;
    font-family: inherit;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .br-product-popup {
    position: relative;
    max-width: 1042px;
    overflow: hidden;
    margin: auto;

    .br-product__thumb img {
      height: 100%;
    }

    .br-product__media-wrapper {
      padding-right: 30px;
    }
  }
}
