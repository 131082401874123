// CSS background
.br-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.br-background-parallax {
  background-attachment: fixed;
}

.br-background-color {
  background-color: $bg-color;
  overflow: hidden;
  padding: 50px 30px 40px 30px;
}

.br-background-color-half {
  position: relative;
  display: block;
  padding-top: 60px;

  &:after {
    content: "";
    background-color: $bg-color;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    display: block;
    z-index: -1;
  }
}

.br-background-overlay {
  position: relative;

  &:after {
    content: "";
    @include overlay($secondary-color,0.5);
  }
}

// CSS .br-banner-poster
.br-banner-poster {
  padding: 30px;
  position: relative;

  @include media('>=sm') {
    height: 660px;
  }

  > .container, .row {
    height: 100%;
  }

  .col-md-6 {
    position: relative;
    height: 100%;
  }
}

.br-banner-poster-text {
  position: relative;
  letter-spacing: 1.5px;
  padding: 60px 0;

  @include media('>=sm') {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
  }

  > h2 {
    line-height: 1;
    margin-bottom: 30px;
  }

  h4 {
    font-weight: 400;
    margin-bottom: 60px;
  }

  .btn {
    width: 150px;
  }
}

.bg-small-promote, .bg-small-promote-2 {
  margin-bottom: 40px;
}

.banner-small-promote {
  position: relative;
  z-index: 1;
  padding-top: 161%;

  .banner-small-promote__content {
    @include absolute(60px,20px,60px,20px);
    padding: 30px 20px;
    background-color: white;
    z-index: 5;

    span {
      display: block;
    }

    &:after {
      content: "";
      @include absolute(4px,4px,4px,4px);
      border: 2px solid black;
    }
  }
}

.banner-small-promote-2 {
  position: relative;
  z-index: 1;
  padding-top: 157%;

  &:after {
    content: "";
    @include overlay($secondary-color,0.5);
  }

  @include media('>=lg') {
    padding-top: 160%;
  }

  .banner-small-promote__content {
    @include absolute(0,0,0,0);
    padding: 70px 35px 50px 35px;
    z-index: 10;

    span {
      display: block;
    }

    p.font-secondary {
      line-height: 1;
      margin-bottom: 67px;

      span {
        position: relative;
        margin-bottom: -12px;

        &:before, &:after {
          content: "";
          position: absolute;
          left: 50%;
          height: 75px;
          width: 1px;
          background-color: white;
          transform: rotate(30deg);
        }

        &:before {
          top: -70px;
          margin-left: 26px;
        }

        &:after {
          bottom: -70px;
          margin-left: -29px;
        }
      }
    }
  }
}

.bg-slide-7 {
  height: 600px !important;
}

@for $i from 1 through 13 {
  .bg-#{$i} {
    background-image: url('../img-demo/bg-page-title/#{$i}.jpg');
  }
}

@for $i from 1 through 7 {
  .bg-slide-#{$i} {
    background-image: url('../img-demo/bg-slides/slide-#{$i}.jpg');
  }
}

.bg-404 {
  background-image: url('../img-demo/backgrounds/bg-404.jpg');
}

.bg-customersay {
  background-image: url('../img-demo/backgrounds/bg-customersay.jpg');
}

.bg-comming-soon {
  background-image: url('../img/comming-soon.jpg');
}

.bg-poster {
  background-image: url('../img-demo/bg-slides/slide-poster.jpg');
}

.bg-small-promote {
  background-image: url('../img-demo/banners/banner-small-5.jpg');
}

.bg-small-promote-2 {
  background-image: url('../img-demo/banners/banner-small-6.jpg');
}

.bg-slide-5 {
  @include overlay($secondary-color,0.4);
}
