.nav-social, .nav-social ul {
  @extend .list-style-none;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  a {
    font-size: 20px;

    span {
      padding: 0 20px 0 5px;
    }
  }
}


.br-site-social {
  padding: 30px 0;
  text-align: center;
  border-top: $border-solid;
  border-bottom: $border-solid;
}

.container .br-site-social {
  border-left: $border-solid;
  border-right: $border-solid;
}

.nav-social-wrapper {

  > span {
    margin-right: 10px;
    color: $secondary-color;
    font-size: 12px;
    font-weight: 700;
  }

  span, .nav-social {
    display: inline-block;
  }

  .nav-social a,
  .nav-social ul a {
    font-size: 14px;
  }

  .nav-social li:not(:last-child),
  .nav-social ul li:not(:last-child) {
    margin-right: 10px;
  }
}
