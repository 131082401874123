// CSS post
.post {
  position: relative;
  margin-bottom: 50px;
}

.entry-header {
  position: relative;
}

.entry-media {
  @extend .br-img;

  &:before {
    padding-top: 65%;
  }
}

.label-sticky {
  color: white;
  @include absolute(0,20px,0,null);
  z-index: 10;

  width: 40px;
  height: 43px;
  background-color: $primary-color;
  padding: 10px 13px;

  &:after {
    content: "";
    @include absolute(null,0,-10px,0);
    width: 0;
    height: 0;

    border-left: 20px solid $primary-color;
    border-right: 20px solid $primary-color;
    border-bottom: 10px solid transparent;
    z-index: -1;
  }
}

.entry-container {
  padding: 25px 0;
}

.post-time {
  @extend .h5;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.entry-title {
  font-size: 30px;
  font-weight: 400;
  font-family: $font-family-playfair;
  text-transform: capitalize;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 15px;
}

.entry-meta-list {
  @extend .list-style-none;
  overflow: hidden;

  li {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-family-montserrat;
    font-size: 12px;
    margin-right: 8px;

    a {
      font-weight: 700;
    }

    .link-comment,
    .link-like {
      color: $text-color;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.post-content {
  > p {
    margin-bottom: 25px;
  }

  .br-card-wrapper .br-card__thumb:before {
    padding-top: 64.76%;
  }
}

.post-info {
  nav {
    display: inline-block;
  }

  @include media('>=sm') {
    .entry-meta-list {
      display: inline-block;
      float: right;
    }
  }
}

// CSS .post-video
.entry-media .br-play {
  font-size: 50px;

  width: 60px;
  height: 60px;
  line-height: 58px;

  @include absolute(50%,null,null,50%);
  margin-top: -30px;
  margin-left: -30px;
  transition: all ease 0.25s;
}

.post:hover .entry-media .br-play, .post:focus .entry-media .br-play {
  background-color: rgba(white,0.4);
}

// CSS .post-gallery
.entry-media-gallery .js-entry-gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  img {
    width: auto;
    position: relative;
  }
}

.entry-gallery__item {
  position: relative;

  .entry-description-picture {
    @include absolute(null,0,0,0);
    padding: 5px 15px;
    background-color: rgba(black,0.3);
    z-index: 20;
    color: white;

    @include media('>=xs') {
      padding: 15px 20px;
    }

    p {
      margin-bottom: 0;
    }
  }
}


// CSS post-quote
.post-quote {
  .entry-header:before {
    content: "";
    @include overlay($secondary-color,0.4);
  }
  .entry-quote {
    @include absolute(50%,null,null,null);
    transform: translateY(-50%);
    text-align: center;
    z-index: 5;
    padding-left: 120px;
    padding-right: 120px;

    blockquote {
      padding: 70px 0 0;
      margin: 0;
    }

    @include media('<sm') {
      padding-left: 20px;
      padding-right: 20px;

      blockquote {
        font-size: 18px;
      }
    }

    @include media('<xs') {
      padding-left: 10px;
      padding-right: 10px;

      blockquote {
        padding: 30px 0 0;
        font-size: 18px;

        &:before {
          font-size: 40px;
        }
      }
    }
  }
}

// CSS .entry-media-audio
.entry-media-audio:before, .entry-media-link:before {
  display: none;
}

// CSS .entry-media-link
.entry-media-link {
  @extend .br-background-color;
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;

  font-size: 30px;
  font-family: $font-family-secondary;

  > a {
    display: block;
    position: relative;
    padding-top: 30px;
    color: $secondary-color;

    &:before {
      @include fa("\f0c1");
      @include absolute(0,0,null,0);
      text-align: center;
      color: $text-color;
    }

    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

// CSS .post-textonly
.post-textonly {
  text-align: center;
  margin-bottom: 0;
  padding: 0;
  border-top: $border-solid;
  transition: all ease 0.25s;

  &:last-child {
    border-bottom: $border-solid;
  }

  .entry-container {
    padding: 90px 30px;
  }

  .label-sticky {
    right: 50%;
    transform: translateX(50%);
  }

  blockquote {
    font-size: 20px;
    margin-bottom: 15px;
  }

  blockquote footer, blockquote small, blockquote .small {
    font-size: 12px;
  }

  &:hover, &:focus {
    background-color: $bg-color;
  }
}

// CSS .js-post-mini
.js-post-mini {
  .entry-title {
    font-size: 20px;
    overflow: inherit;
    white-space: inherit;
  }

  .post {
    padding: 20px 80px;
    margin-bottom: 0;
  }
}
