.font--secondary {
  font-family: $font-family-secondary;
}

.breadcrumb {
  margin-bottom: 0;

  > .active a {
    color: $secondary-color !important;
  }
}

.br-img {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: "";
    position: relative;
    padding-top: 136.882129%;
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

}

.br-select-color {
  padding: 5px;
  display: inline-block;

  a {
    float: left;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 0;
    position: relative;

    &:after {
      content: "";
      @include absolute(-3px, -3px, -3px, -3px);
      border: 1px solid transparent;
      background-color: transparent;
      display: block;
      border-radius: 50%;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      &:after {
        border-color: #bcbcbc;
      }
    }
  }
}


.br-border {
  border: $border-solid;
}

.br-title {
  @extend .h1;
  text-transform: uppercase;
  margin-bottom: 30px;

  &.br-title--white {
    color: white;
  }
}

.br-category, .br-tags {
  @extend .list-style-none;
  display: inline-block;
  vertical-align: middle;

  li {
    float: left;
    font-size: 12px;
    font-weight: 700;

    a {
      padding: 0 2px;
    }
  }
}

.br-category li:not(:last-child):after {
  content: "/";
}

.br-tags li:not(:last-child):after {
  content: ",";
}

.br-play {
  font-size: 26px;
  font-weight: 400;
  color: white;

  width: 36px;
  height: 36px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(black,0.2);

  .tooltip-inner {
    font-family: $font-family-third;
    letter-spacing: 3px;
  }

  &:hover, &:focus {
    color: darken(white,5%);
  }
}

.br-enlarge {
  width: 50px;
  height: 50px;
  color: black;

  a {
    display: block;
    height: 100%;
  }

  .br-plus {
    position: absolute;
    right: 7px;
    bottom: 7px;
    line-height: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;

    border: 25px solid transparent;
    border-bottom-color: white;
    border-right-color: white;
  }

  &:hover, &:focus {
    color: $primary-color;
  }

  &:focus {
    outline: none;
  }
}

.br-logo-wrapper {
  font-family: $font-family-third;
  font-size: 12px;

  .br-logo {
    display: block;
    margin-bottom: 20px;
  }
}

.br-payment {
  a {
    font-size: 16px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.br-site-info {
  font-family: $font-family-third;
  font-size: 12px;

  padding: 20px 0;
  border-top: $border-solid;

  a {
    color: $text-color;

    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

.br-site-contact {
  a {
    padding-right: 15px;

    &:not(:last-child):after {
      content: "|";
      margin-left: 15px;
      color: #444 !important;
    }
  }
}

// CSS .br-slogan
.br-slogan {
  font-size: 30px;
  font-weight: 400;
  font-family: $font-family-secondary;

  @include media('>=md') {
    font-size: 40px;
  }
}

.br-slogan-note {
  font-family: $font-family-base;
}

.demo-select {
  .select2-container {
    min-width: 180px;
    width: auto !important;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

.heading-page {
  font-family: $font-family-secondary;
  font-weight: 400;
}

.tag-sale i {
  color: #e93c4f;
  margin-left: 5px;
}

.js-imgtobg {
  img {
    display: none;
  }
}

// CSS .br-cat
.br-cat a {
  @extend .h6;
  display: block;
  text-transform: uppercase;
}

.br-cat-wrapper {
  margin-right: 5px;

  h6 span {
    color: $text-color;
    font-weight: 400;
  }

  a {
    color: $text-color;
    font-weight: 400;

    i {
      margin-left: 10px;
      font-size: 14px;
    }

    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

// CSS .br-portfolio-detail
.br-portfolio-detail {
  line-height: 40px;

  .br-slick-outline .slick-next,
  .br-slick-outline .slick-prev {
    margin-top: 0;
    top: 0;
    transform: none;
  }
}

.list-payments {
  padding-left: 30px;
}

.mfp-zoom-out-cur {
  cursor: auto;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  width: 44px;
  text-align: center;
  color: white !important;
  opacity: 1 !important;
  font-weight: 700 !important;
  font-size: 30px !important;

  top: -8px;
  right: -45px;
  cursor: pointer !important;
}

.mfp-image-holder .mfp-figure:after {
  box-shadow: none;
}

img.mfp-img {
  cursor: zoom-out;
}
