@charset "UTF-8";
// Default Variables
$slick-font-path: "./fonts/" !default;
$slick-font-family: "FontAwesome" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: $secondary-color !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f177" !default;
$slick-next-character: "\f178" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-size: 8px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;
@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  }
  @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  }
  @else {
    @return url($slick-font-path + $url);
  }
}


/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}


/* Icons */

@if $slick-font-family=="slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}


/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 10;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
    /*lte IE 8*/
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus,
    &:active:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: $slick-font-family;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -10px;
    [dir="rtl"] & {
      left: auto;
      right: -10px;
    }
    &:before {
      content: $slick-prev-character;
      [dir="rtl"] & {
        content: $slick-next-character;
      }
    }
  }

  .slick-next {
    right: -10px;
    [dir="rtl"] & {
      left: -10px;
      right: auto;
    }
    &:before {
      content: $slick-next-character;
      [dir="rtl"] & {
        content: $slick-prev-character;
      }
    }
  }


/* Dots */

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin-bottom: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin: 0 2.5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 8px;
      width: 8px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:active:focus {
        background-color: transparent;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 8px;
        height: 8px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 8px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      content: "\f1db";
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

// .br-slick-white
.br-slick-white {
  .slick-prev,
  .slick-next {
    background-color: $secondary-color;

    &:hover, &:focus {
      background-color: $primary-color;
    }

    &:before {
      font-size: 14px;
      color: white;
    }

    &.slick-disabled {
      background-color: rgba($secondary-color,0.4);
    }
  }

  .slick-dots li.slick-active button:before {
    color: $primary-color;
  }

  .slick-prev {
    left: 0;
    [dir="rtl"] & {
      right: 0;
    }
  }

  .slick-next {
    right: 0;
    [dir="rtl"] & {
      left: 0;
    }
  }

  .slick-dots li {
    margin: 0 5px;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 8px;
  }
}

// br-slick-white-2
.br-slick-white-2 {
  .slick-prev:before, .slick-next:before {
    color: white;
  }

  .slick-dots li{
    margin: 0 5px;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: $primary-color;
  }
}

// br-slick-outline
.br-slick-outline {
  .slick-next, .slick-prev {
    margin-top: -10px;
    border: $border-solid;

    &:before {
      font-size: 14px;
    }

    &:hover, &:focus {
      background-color: $secondary-color;
      border-color: transparent;

      &:before {
        color: white;
      }
    }
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    right: 40px;
    left: auto;
    border-right: none;
  }
}

.br-slick-animation {

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  @include media('>=xs') {

    .slick-prev, .slick-next {
      opacity: 0;
      visibility: hidden;
      transition-timing-function: cubic-bezier(0.29, 0.61, 0.48, 0.37);
      transition-duration: 0.2s;
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }

    &:hover, &:focus {
      .slick-prev, .slick-next {
        opacity: 1;
        visibility: visible;
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 0;
      }
    }
  }
}
