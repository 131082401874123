.site-about {
  color: $secondary-color;
}

.br-team__media {
  position: relative;
  margin-bottom: 25px;

  .nav-social {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translate(-50%, -50%);

    opacity: 0;
    visibility: hidden;
    transition: all ease 0.45s;

    a {
      color: white;
      font-size: 16px;

      &:hover, &:focus {
        color: $primary-color;
      }
    }
  }

  .nav-social li:not(:last-child), .nav-social ul li:not(:last-child) {
    margin-right: 15px;
  }
}

.br-team:hover, .br-team:focus {
  .br-team__media .nav-social {
    left: 50%;
    opacity: 1;
    visibility: visible;
    z-index: 6;
  }

  .br-team__thumb:after {
    width: 100%;
  }
}

.br-team__info .br-team__name {
  font-weight: 400;
  margin-bottom: 5px;
}

.js-br-team.br-slick-white {
  .slick-next, .slick-prev {
    margin-top: -40px;
  }

  .slick-prev {
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }

  @include media('>=md') {
    .slick-prev {
      left: -25px;
    }

    .slick-next {
      right: -25px;
    }
  }
}
