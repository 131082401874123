.pagination > li > a, .pagination > li > span {
  min-width: 40px;
  height: 40px;
  line-height: 37px;
  padding: 0 10px;
  text-align: center;

  i {
    font-size: 10px;
    font-weight: 400;
  }
}

.br-pagination {
  padding: 40px 0;
  margin-bottom: 60px;
  border-top: $border-solid;

  .pagination {
    margin: 0;
  }
}
