.js-new-arrivals {

  .slick-arrow {
    margin-top: -40px;
  }

  .slick-next {
    right: 15px;
  }

  .slick-prev {
    left: 15px;
  }

  @include media('>=lg') {
    .slick-next {
      right: -27px;
    }

    .slick-prev {
      left: -25px;
    }
  }
}

.js-br-new {
  .post {
    margin-bottom: 0;
  }

  .entry-container {
    padding-bottom: 0;
  }

  .slick-arrow {
    margin-top: -90px;
  }

  .slick-next {
    right: 5px;
  }

  .slick-prev {
    left: 5px;
  }

  @include media('>=md') {
    .slick-next {
      right: -60px;
    }

    .slick-prev {
      left: -60px;
    }
  }
}

.js-br-new2 {
  .slick-arrow {
    margin-top: -30px;
  }

  @include media('<xs') {
    .slick-next {
      right: 15px;
    }

    .slick-prev {
      left: 15px;
    }
  }
}

.js-br-new2,
.js-portfolio-slider {
  @include media('>=md') {
    .slick-next {
      right: -25px;
    }

    .slick-prev {
      left: -24px;
    }
  }
}

// CSS .js-portfolio-slider
.js-portfolio-slider {
  overflow: visible;

  .slick-next,
  .slick-prev {
    margin-top: -25px;
  }
}

// slide-banner
.slide-banner {
  .slick-prev, .slick-next {
    margin-top: 0;
  }

  .slick-dots {
    position: absolute;
    bottom: 18px;
    z-index: 10;
  }

  .br-card__thumb {
    &:after {
      content: "";
      @include overlay($secondary-color,0.2);
    }

    &:before {
      padding-top: 100% !important;
    }
  }
}

.slide-banner-content {
  span {
    display: block;
  }

  .fz-30 {
    line-height: 1;
  }

  h2 {
    font-size: 40px;
    font-weight: 400;

    @include media('>=md') {
      font-size: 60px;
    }
  }
}

.slide-banner-full .br-background {
  padding: 30px;
  position: relative;

  @include media('>=sm') {
    height: 660px;
  }

  > .container, .row {
    height: 100%;
  }

  .col-md-6 {
    position: relative;
    height: 100%;
  }
}

.slide-banner-full-content {
  position: relative;
  letter-spacing: 0px;
  padding: 60px 0;

  @include media('>=sm') {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
  }

  > h2 {
    line-height: 1;
    margin-bottom: 30px;
  }

  h4 {
    font-weight: 400;
    margin-bottom: 60px;
  }

  .btn {
    width: 150px;
  }

  span {
    display: block;
  }

  .fz-60 {
    line-height: 1;
  }
}

// CSS js-slide-banner-full-1
.js-slide-banner-full-1 {
  .slick-dots {
    position: absolute;
    bottom: 25px;
  }
}

// CSS .slide-banner-full-2
@include media('<sm') {
  .slide-banner-full-2 {
    text-align: center;
  }
}

.slide-banner-slide-3 {
  text-align: center;

  h2 {
    font-size: 70px;
    font-weight: 400;
    line-height: 0.95;
    margin-bottom: 0;

    @include media('>=sm') {
      font-size: 140px;
    }
  }
}

.slide-banner-slide-4,
.slide-banner-slide-5,
.slide-banner-slide-6,
.slide-banner-slide-7  {
  h2 {
    font-size: 40px;
    font-weight: 400;
    line-height: 1;

    @include media('>=sm') {
      font-size: 80px;
    }
  }

  a {
    margin: 5px;
  }
}

.slide-banner-slide-5 {
  text-align: center;
}

.slide-banner-slide-6 {
  text-align: center;

  @include media('>=sm') {
    .pl-md-60 {
      padding-left: 60px;
    }
  }

  h2 span {
    position: relative;
    z-index: 2;
    padding-top: 10px;

    @include media('>=sm') {
      display: inline-block;
      padding-top: 0;

      &:after, &:before {
        content: "";
        width: 2px;
        background-color: $secondary-color;

        @include absolute(null,null,null,50%);
        transform: translateX(-50%);
      }

      &:before {
        top: -300%;
        margin-top: 20px;
        height: 80px;

        @include media('>=sm') {
          top: -200%;
        }
      }

      &:after {
        bottom: -300%;
        margin-bottom: 10px;
        height: 120px;

        @include media('>=sm') {
          bottom: -200%;
          margin-bottom: 20px;
        }
      }
    }
  }

  a {
    position: relative;
    z-index: 5;
  }
}

// CSS .br-contact-info
.br-contact-info {
  .select2-container .select2-selection--single {
    height: 50px;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -14px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
    content: "\f063";
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: 400;
  }

  .nav-social a {
    font-size: 16px;
  }

  .nav-social li:not(:last-child),
  .nav-social ul li:not(:last-child) {
    margin-right: 15px;
  }
}

// CSS #map
#contact-map {
  height: 600px;
}
[data-map="marker"] {
  display: none;
}

// CSS .br-heading
.br-heading {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 60px;
    margin-bottom: 5px;
  }
}

.site-header {
  @include clearfix;
}

.masonry-testimonial {
  margin: 0 -15px;
}
